import {API_PATH} from '@app/common/constants';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {formatFilters} from '@app/utils/util';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {staffHoldStateSelector} from './selectors';
import {appSelector} from '@app/store/selectors';
import {
  fetchStaffHoldCreatorTypeName,
  updateStaffHoldCreatorTypeName,
  fetchEmployeesOnHoldCreatorTypeName,
  fetchStaffHoldHistoryCreatorTypeName,
  updateEmployeeHoldStatusCreatorTypeName,
  staffHoldStateActions,
  fetchStaffHoldSuggestionsCreatorTypeName,
  fetchHoldEmployeeSuggestionsCreatorTypeName,
  fetchStaffHoldDeepdiveCreatorTypeName,
  fetchStaffHoldFilterCreatorTypeName,
  resignedStaffHoldCreatorTypeName,
  updateStaffHoldEmailNotificationCreatorTypeName
} from './slice';
import moment from 'moment';

/**
 * Staff hold watcher
 */
export function* fetchStaffHoldDataWatcher() {
  yield takeLatest(
    fetchStaffHoldCreatorTypeName,
    fetchStaffHoldDataWatcherWorker,
  );
}

/**
 * Staff hold Worker
 */
export function* fetchStaffHoldDataWatcherWorker(): any {
  try {
    yield put(staffHoldStateActions.setStaffHoldLoading(true));
    yield put(appSliceActions.setExcelDataLoading(true));
    const employeeId = '';
    const stateByAdminData = yield select(appSelector.getStateDropDown());
    const divisionByAdminData = yield select(appSelector.getDivisionDropDown());
    const divisionIds = yield select(appSelector.getDivisionDropDownIds());
    const stateIds = yield select(appSelector.getStateDropDownIds());
    const filters = yield select(filterSelector.getFilterState());
    const filterApplied = yield select(filterSelector.getFilterApplied());
    const filter = yield select(filterSelector.getFilterOptions());
    const userDetails = yield select(appSelector.getUserInfo());

    if (!filterApplied) {
      // if (divisionByAdminData.length > 0) {
      const options: any = {};
      const optionsState: any = {};
      divisionByAdminData?.map((item: any) => {
        options[item.label] = false;
        return null;
      });

      stateByAdminData?.map((item: any) => {
        optionsState[item.label] = false;
        return null;
      });
      yield put(
        filterStateActions.setFilterState({
          ...filters,
          Division: {
            completeApplied: false,
            collapsed: true,
            options,
          },
          States: {
            completeApplied: false,
            collapsed: true,
            options: optionsState,
          },
        }),
      );
      // }
    }
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.getAll}${employeeId}`;
    let pageNo = yield select(staffHoldStateSelector.getStaffHoldsPageNo());
    let perPage = yield select(staffHoldStateSelector.getStaffHoldsPerPage());
    let request = {
      pageNo: pageNo + 1,
      rowPerPage: perPage,
      stateIds: stateIds,
      divisionIds: divisionIds,
      roleIds: null,
      searchData: '',
      staffpositionId: userDetails?.staffPositionId,
    };
    if (filterApplied) {
      request = {
        ...filter,
        pageNo: pageNo + 1,
        rowPerPage: perPage,
        staffpositionId: userDetails?.staffPositionId,
      };
      if (filter.stateIds.length == 0) {
        request.stateIds = stateIds;
      }
      if (filter.divisionIds.length == 0) {
        request.divisionIds = divisionIds;
      }
    }
    const response = yield call(NetworkService.post, url, request, {});

    yield put(
      staffHoldStateActions.setStaffHold(response.data?.staffHoldDtoList),
    );
    yield put(staffHoldStateActions.setTotalCount(response.data?.totalCount));
    yield put(staffHoldStateActions.setStaffHoldLoading(false));

    let requestExcel = {
      ...request,
      pageNo: 1,
      rowPerPage: response?.data?.totalCount,
      staffpositionId: userDetails?.staffPositionId,
    };

    const responseExcel = yield call(
      NetworkService.post,
      url,
      requestExcel,
      {},
    );

    const getDisabledStatus = (user: any, property: string): string => {
      return user[property];
    };

    const transformedData = responseExcel?.data?.staffHoldDtoList?.map(
      (user: any) => ({
        userId: user?.userId,
        employeeId: user?.employeeId,
        employeeName: user?.employeeName,
        managerName: user?.managerName,
        divisionId: user?.divisionId,
        division: user?.division,
        roleId: user?.roleId,
        role: user?.role,
        isSalaryDisabled: getDisabledStatus(user, 'isSalaryDisabled'),
        isExpenseDisabled: getDisabledStatus(user, 'isExpenseDisabled'),
        isDCRDisabled: getDisabledStatus(user, 'isDcrDisabled'),
        isIncentiveDisabled: getDisabledStatus(user, 'isIncentiveDisabled'),
        isInputAndSamplesDisabled: getDisabledStatus(
          user,
          'isInputAndSamplesDisabled',
        ),
        stateId: user?.stateId,
        state: user?.state,
        staffHoldHistories: user?.staffHoldHistories,
        staffHoldDisables: user?.staffHoldDisables,
      }),
    );

    yield put(
      appSliceActions.setExcelData({
        data: transformedData,
        headers: [
          {label: 'Employee ID', key: 'employeeId'},
          {label: 'Name', key: 'employeeName'},
          {label: 'Role', key: 'role'},
          {label: 'Division', key: 'division'},
          {label: "Manager's Name", key: 'managerName'},
          {label: 'Expense', key: 'isExpenseDisabled'},
          {label: 'DCR', key: 'isDCRDisabled'},
          {label: 'Incentive', key: 'isIncentiveDisabled'},
          {label: 'Input and Samples', key: 'isInputAndSamplesDisabled'},
          {label: 'Salary', key: 'isSalaryDisabled'},
        ],
      }),
    );
    yield put(staffHoldStateActions.setStaffHoldLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  } finally {
    yield put(staffHoldStateActions.setStaffHoldLoading(false));
    yield put(appSliceActions.setExcelDataLoading(false));
  }
}

export function* fetchStaffHoldFilterDataWatcher() {
  yield takeLatest(
    fetchStaffHoldFilterCreatorTypeName,
    fetchStaffHoldFilterDataWatcherWorker,
  );
}

export function* fetchStaffHoldFilterDataWatcherWorker(): any {
  try {
    yield put(staffHoldStateActions.setStaffHoldLoading(true));
    const employeeId = '';
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.getAll}${employeeId}`;
    let pageNo = yield select(staffHoldStateSelector.getStaffHoldsPageNo());
    let perPage = yield select(staffHoldStateSelector.getStaffHoldsPerPage());
    const filter = yield select(filterSelector.getFilterOptions());
    let request = {
      ...filter,
      pageNo: pageNo + 1,
      rowPerPage: perPage,
    };
    const response = yield call(NetworkService.post, url, request, {});

    yield put(
      staffHoldStateActions.setStaffHold(response.data?.staffHoldDtoList),
    );
    yield put(staffHoldStateActions.setTotalCount(response.data?.totalCount));
    yield put(staffHoldStateActions.setStaffHoldLoading(false));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  } finally {
    yield put(staffHoldStateActions.setStaffHoldLoading(false));
  }
}

/**
 * Staff hold update watcher
 */
export function* updateStaffHoldEmailNotificationWatcher() {
  yield takeLatest(
    updateStaffHoldEmailNotificationCreatorTypeName,
    updateStaffHoldEmailNotificationWatcherWorker,
  );
}

export function* updateStaffHoldEmailNotificationWatcherWorker(action: any): any {
  try {
    const url =  `${API_PATH.employeeSpecificUtilities.staffHold.emailNotification}`;
    const response = yield call(NetworkService.post, url, action?.payload, {});
  } catch (error) {
  } finally {
  }
}
export function* updateStaffHoldDataWatcher() {
  yield takeLatest(
    updateStaffHoldCreatorTypeName,
    updateStaffHoldDataWatcherWorker,
  );
}

/**
 * Staff hold update Worker
 */
export function* updateStaffHoldDataWatcherWorker(action: any): any {
  try {
    yield put(staffHoldStateActions.setStaffHoldLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.update}`;

    const startDate =
      action?.payload?.payLoadReq?.startDate != null
        ? new Date(action.payload?.payLoadReq?.startDate)?.toLocaleDateString(
            'en-ca',
          )
        : '';
    const endDate =
      action?.payload?.payLoadReq?.endDate != null
        ? new Date(action.payload?.payLoadReq?.endDate)?.toLocaleDateString(
            'en-ca',
          )
        : '';

    const enableDisableType =
      action?.payload?.payLoadReq?.startDate != null ? 'DISABLED' : 'ENABLED';

    const userName = action?.payload?.payLoadData?.employeeName;

    const response = yield call(NetworkService.post, url, {
      ...action?.payload?.payLoadReq,
      startDate,
      endDate,
    });

    yield put(staffHoldStateActions.setUpdatedStuffHoldRow(response?.data));
    yield put(staffHoldStateActions.setStaffHoldLoading(false));

    if (response?.status === 200) {
      ToasterService.showToaster(
        `Feature Types: ${response?.data?.featureTypeName.join(
          ', ',
        )} is ${enableDisableType} for user ${userName} from start date :${
          startDate === ''
            ? moment(new Date()).format('DD-MM-YYYY')
            : moment(startDate).format('DD-MM-YYYY')
        } `,
        ToastType.ENABLE_DISABLE_SUCCESS,
      );
      yield call(fetchStaffHoldDataWatcherWorker);
    } else {
      ToasterService.showToaster(
        response?.data?.error,
        ToastType.ENABLE_DISABLE_ERROR,
      );
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staff hold!',
      ToastType.ERROR,
    );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  } finally {
    yield put(staffHoldStateActions.setStaffHoldLoading(false));
  }
}

/**
 * Employees on hold watcher
 */
export function* fetchEmployeesonHoldDataWatcher() {
  yield takeLatest(
    fetchEmployeesOnHoldCreatorTypeName,
    fetchEmployeesonHoldDataWatcherWorker,
  );
}

/**
 * Employees on hold Worker
 */

export function* fetchEmployeesonHoldDataWatcherWorker(): any {
  try {
    yield put(staffHoldStateActions.setEmployeesOnHoldLoading(true));

    const filterApplied = yield select(filterSelector.getFilterApplied());

    const url = `${API_PATH.employeeSpecificUtilities.staffHold.employeesOnHold}`;
    let pageNo = yield select(
      staffHoldStateSelector.getEmployeesOnHoldPageNo(),
    );
    let perPage = yield select(
      staffHoldStateSelector.getEmployeesOnHoldrowsPerPage(),
    );
    const userInfo = yield select(appSelector.getUserInfo());
    const filter = yield select(filterSelector.getFilterOptions());

    let request = {
      userId: userInfo?.userId,
      pageNo: pageNo + 1,
      rowPerPage: perPage,
      roleIds: null,
      searchData: '',
    };
    if (filterApplied) {
      request = {
        roleIds: filter.roleIds,
        userId: userInfo?.userId,
        pageNo: pageNo + 1,
        rowPerPage: perPage,
        searchData: filter.searchData,
      };
    }
    const response = yield call(NetworkService.post, url, request, {});

    yield put(
      staffHoldStateActions.setEmployeesOnHoldTotalCount(
        response.data?.totalCount,
      ),
    );
    yield put(
      staffHoldStateActions.setEmployeesOnHold(
        response.data?.staffHoldEmployeeList,
      ),
    );
    yield put(staffHoldStateActions.setEmployeesOnHoldLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staff hold list of employees of hold!',
      ToastType.ERROR,
    );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  } finally {
    yield put(staffHoldStateActions.setEmployeesOnHoldLoading(false));
  }
}

/**
 * Staff hold watcher
 */
export function* fetchStaffHoldHistoryWatcher() {
  yield takeLatest(
    fetchStaffHoldHistoryCreatorTypeName,
    fetchStaffHoldHistoryWatcherWorker,
  );
}

/**
 * Staff hold Worker
 */
export function* fetchStaffHoldHistoryWatcherWorker(action: any): any {
  try {
    yield put(staffHoldStateActions.setHistoryLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.history}/${action?.payload}`;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(staffHoldStateActions.setHistory(response.data));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staff hold history!',
      ToastType.ERROR,
    );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  } finally {
    yield put(staffHoldStateActions.setHistoryLoading(false));
  }
}

/**
 * Staff hold watcher
 */
export function* updateEmployeeHoldStatusWatcher() {
  yield takeLatest(
    updateEmployeeHoldStatusCreatorTypeName,
    updateEmployeeHoldStatusWatcherWorker,
  );
}

/**
 * Staff hold Worker
 */
export function* updateEmployeeHoldStatusWatcherWorker(): any {
  try {
    // update employee hold status
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching selected employees!',
    //   ToastType.ERROR,
    // );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  }
}

/**
 * Staff hold suggestions watcher
 */
export function* fetchStaffHoldSuggestionsWatcher() {
  yield takeLatest(
    fetchStaffHoldSuggestionsCreatorTypeName,
    fetchStaffHoldSuggestionsWatcherWorker,
  );
}

/**
 * Staff hold suggestions Worker
 */
export function* fetchStaffHoldSuggestionsWatcherWorker(action: any): any {
  try {
    yield put(staffHoldStateActions.setStaffHoldSuggestionsLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.suggest}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(staffHoldStateActions.setAutoSuggestData(response.data));
    yield put(staffHoldStateActions.setStaffHoldSuggestionsLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staff hold suggestions!',
      ToastType.ERROR,
    );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
    yield put(staffHoldStateActions.setStaffHoldSuggestionsLoading(false));
  }
}

/**
 * Staff hold List of Employees on Hold suggestions watcher
 */
export function* fetchHoldEmployeeSuggestionsWatcher() {
  yield takeLatest(
    fetchHoldEmployeeSuggestionsCreatorTypeName,
    fetchHoldEmployeeSuggestionsWatcherWorker,
  );
}

/**
 * Staff hold List of Employees on Hold suggestions Worker
 */
export function* fetchHoldEmployeeSuggestionsWatcherWorker(action: any): any {
  try {
    yield put(staffHoldStateActions.setStaffHoldSuggestionsLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.suggest}`;
    const response = yield call(
      NetworkService.get,
      url,
      {
        searchData: action?.payload,
      },
      {},
    );
    yield put(
      staffHoldStateActions.setEmployeesOnHoldAutoSuggestData(response.data),
    );
    yield put(staffHoldStateActions.setStaffHoldSuggestionsLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staff hold suggestions!',
      ToastType.ERROR,
    );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  }
}

/**
 * Staff hold watcher
 */
export function* fetchStaffHoldDeepdiveWatcher() {
  yield takeLatest(
    fetchStaffHoldDeepdiveCreatorTypeName,
    fetchStaffHoldDeepdiveWatcherWorker,
  );
}

/**
 * Staff hold Worker
 */
export function* fetchStaffHoldDeepdiveWatcherWorker(action: any): any {
  try {
    yield put(staffHoldStateActions.setDeepdive([]));
    yield put(staffHoldStateActions.setDeepdiveLoading(true));
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.deepdive}/${action?.payload}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status !== 200) {
      ToasterService.showToaster(response?.data?.error, ToastType.ERROR);
    } else {
      yield put(staffHoldStateActions.setDeepdive(response?.data));
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching staff hold deepdive!',
      ToastType.ERROR,
    );
    yield put(staffHoldStateActions.setStaffHoldError(error));
    setTimeout(function* () {
      yield put(staffHoldStateActions.clearStaffHoldError());
    }, 5000);
  } finally {
    yield put(staffHoldStateActions.setDeepdiveLoading(false));
  }
}

/**
 * Resigned/Revoked Staff Hold Watcher
 */
export function* resignedStaffHoldWatcher() {
  yield takeLatest(resignedStaffHoldCreatorTypeName, resignedStaffHoldWorker);
}

/**
 * Resigned/Revoked Staff Hold Worker
 */
export function* resignedStaffHoldWorker(action: any): any {
  try {
    const url = `${API_PATH.employeeSpecificUtilities.staffHold.resign}`;
    const userName = action?.payload?.payLoadData?.employeeName;

    const response = yield call(NetworkService.post, url, {
      ...action?.payload?.payLoadReq,
    });

    if (response?.status === 200) {
      ToasterService.showToaster(
        ` user: ${userName} status has been ${
          action?.payload?.payLoadReq.isResigned ? 'resigned' : 'revoked'
        }.`,
        ToastType.ENABLE_DISABLE_SUCCESS,
      );
      yield call(fetchStaffHoldDataWatcherWorker);
    }
    if (response?.data?.error) {
      ToasterService.showToaster(
        response?.data?.error,
        ToastType.ENABLE_DISABLE_ERROR,
      );
    }
  } catch (error) {
    setTimeout(function* () {}, 5000);
  } finally {
  }
}
