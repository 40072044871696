import {API_PATH, HTTP_OK} from '@app/common/constants';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, takeLatest, put, select} from '@redux-saga/core/effects';
import {EMP360_DEFAULT_OBJECTS} from '@app/common/constants';
import {employee360StateSelector} from './selectors';

import {
  employee360StateActions,
  fetchEmp360DivisionsCreatorTypeName,
  fetchEmp360StatesCreatorTypeName,
  fetchEmp360HQCreatorTypeName,
  fetchEmp360HierarchyCreatorTypeName,
  fetchEmp360SearchUserCreatorTypeName,
  fetchEmp360EmpListCreatorTypeName,
  fetchEmp360EmpExcelCreatorTypeName,
  fetchEmp360ProfilePersonalInformationCreatorTypeName,
  fetchEmp360ProfileEducationCreatorTypeName,
  fetchEmp360ProfileCreatorTypeName,
  fetchEmp360ProfileCourseDetailsCreatorTypeName,
  fetchEmp360ProfileAwardDetailsCreatorTypeName,
  fetchEmp360ProfileForeignTripsCreatorTypeName,
  fetchEmp360ProfileContactDetailsTypeName,
  fetchEmp360ProfileFamilyDetailsTypeName,
  fetchEmp360ProfileCompanyAssetsTypeName,
  fetchEmp360ProfileFeedbacksCreatorTypeName,
  fetchEmp360ProfileAssessmentsCreatorTypeName,
  fetchEmployeesOnHoldDetailsTypeName,
  fetchEmployeesWorkExperienceDetailsTypeName,
  fetchEmp360DefaultGenderAndReligionTypeName,
} from './slice';
import {all} from 'redux-saga/effects';

/**
 * Divisions Watcher
 */
export function* fetchEmp360DivisionsWatcher() {
  yield takeLatest(
    fetchEmp360DivisionsCreatorTypeName,
    fetchEmp360DivisionsWatcherWorker,
  );
}

/**
 * Divisions Worker
 */
export function* fetchEmp360DivisionsWatcherWorker(): any {
  try {
    yield put(employee360StateActions.setEmp360DivisionsLoading(true));
    const fetchedDivisions = yield call(
      NetworkService.get,
      API_PATH.employee360.divisions,
      {},
      {},
    );
    yield put(
      employee360StateActions.setEmp360Divisions(fetchedDivisions?.data),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching divisions!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360DivisionsError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360DivisionsError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360DivisionsLoading(false));
  }
}

/**
 * States Watcher
 */
export function* fetchEmp360StatesWatcher() {
  yield takeLatest(
    fetchEmp360StatesCreatorTypeName,
    fetchEmp360StatesWatcherWorker,
  );
}

/**
 * States Worker
 */
export function* fetchEmp360StatesWatcherWorker(): any {
  try {
    yield put(employee360StateActions.setEmp360StatesLoading(true));
    const fetchedStates = yield call(
      NetworkService.get,
      API_PATH.employee360.states,
      {},
      {},
    );
    yield put(employee360StateActions.setEmp360States(fetchedStates?.data));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching states!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360StatesError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360StatesError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360StatesLoading(false));
  }
}

/**
 * HQ Watcher
 */
export function* fetchEmp360HQWatcher() {
  yield takeLatest(fetchEmp360HQCreatorTypeName, fetchEmp360HQWatcherWorker);
}

/**
 * HQ Worker
 */
export function* fetchEmp360HQWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360HQLoading(true));
    const fetchedHQ = yield call(
      NetworkService.post,
      API_PATH.employee360.hq,
      action.payload,
      {},
    );
    yield put(employee360StateActions.setEmp360HQ(fetchedHQ?.data));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching HQ!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360HQError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360HQError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360HQLoading(false));
  }
}

/**
 * Hierarchy Watcher
 */
export function* fetchEmp360HierarchyWatcher() {
  yield takeLatest(
    fetchEmp360HierarchyCreatorTypeName,
    fetchEmp360HierarchyWatcherWorker,
  );
}

/**
 * Hierarchy Worker
 */
export function* fetchEmp360HierarchyWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360HierarchyLoading(true));
    const fetchedHierarchy = yield call(
      NetworkService.post,
      API_PATH.employee360.hierarchy,
      action.payload,
      {},
    );
    yield put(
      employee360StateActions.setEmp360Hierarchy(fetchedHierarchy?.data),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching hierarchy!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360HierarchyError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360HierarchyError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360HierarchyLoading(false));
  }
}

/**
 * Search User Watcher
 */
export function* fetchEmp360SearchUserWatcher() {
  yield takeLatest(
    fetchEmp360SearchUserCreatorTypeName,
    fetchEmp360SearchUserWatcherWorker,
  );
}

/**
 * Search User Worker
 */
export function* fetchEmp360SearchUserWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360SearchUserLoading(true));
    const fetchedSearchUser = yield call(
      NetworkService.post,
      API_PATH.employee360.searchUser,
      action.payload,
      {},
    );
    yield put(
      employee360StateActions.setEmp360SearchUser(fetchedSearchUser?.data),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching user!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360SearchUserError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360SearchUserError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360SearchUserLoading(false));
  }
}

/**
 * Employee List Watcher
 */
export function* fetchEmp360EmpListWatcher() {
  yield takeLatest(
    fetchEmp360EmpListCreatorTypeName,
    fetchEmp360EmpListWatcherWorker,
  );
}

/**
 * Employee List Worker
 */
export function* fetchEmp360EmpListWatcherWorker(action: any): any {
  try {
    console.log('action in fetchEmp360EmpListWatcherWorker', action);
    yield put(employee360StateActions.setEmp360EmpListLoading(true));
    yield put(employee360StateActions.setEmp360EmpFiltersLoading(true));
    if (action?.payload?.isNewCall)
      yield put(
        employee360StateActions.setEmp360EmpFilters({
          ...EMP360_DEFAULT_OBJECTS.emp360EmpFilters,
        }),
      );
    const fetchedEmpList = yield call(
      NetworkService.post,
      API_PATH.employee360.empList,
      action.payload,
      {},
    );
    yield put(
      employee360StateActions.setEmp360EmpList(
        fetchedEmpList?.data?.employeeList,
      ),
    );
    yield put(
      employee360StateActions.setEmp360EmpListLength(
        fetchedEmpList?.data?.totalRecords,
      ),
    );

    yield put(
      employee360StateActions.setEmp360EmpExcelLatestAction({
        ...action.payload,
        pageNo: 1,
        limit: fetchedEmpList?.data?.totalRecords,
      }),
    );
    if (
      action?.payload?.isNewCall &&
      fetchedEmpList?.data?.filters.length > 0 &&
      fetchedEmpList?.data?.filterRatings.length > 0
    ) {
      yield put(
        employee360StateActions.setEmp360EmpFilters({
          ...fetchedEmpList?.data?.filters,
          ...fetchedEmpList?.data?.filterRatings,
        }),
      );
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching employee list!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360EmpListError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360EmpListError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360EmpListLoading(false));
    yield put(employee360StateActions.setEmp360EmpFiltersLoading(false));
  }
}

/**
 * Excel Download Watcher
 */
export function* fetchEmp360EmpExcelWatcher() {
  yield takeLatest(
    fetchEmp360EmpExcelCreatorTypeName,
    fetchEmp360EmpExcelWatcherWorker,
  );
}

/**
 * Excel Download Worker
 */
export function* fetchEmp360EmpExcelWatcherWorker(action: any): any {
  try {
    console.log('action.payload', action.payload);
    yield put(employee360StateActions.setEmp360EmpExcelLoading(true));
    const fetchedEmpList = yield call(
      NetworkService.post,
      API_PATH.employee360.empList,
      action.payload,
      {},
    );

    if (fetchedEmpList?.data === undefined) {
      throw 500;
    } else {
      yield put(
        employee360StateActions.setEmp360EmpExcel(
          fetchedEmpList?.data?.employeeList,
        ),
      );
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while downloding employee list excel!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360EmpExcelError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360EmpExcelError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360EmpExcelLoading(false));
  }
}

/**
 * Profile Watcher
 */
export function* fetchEmp360ProfileWatcher() {
  yield takeLatest(
    fetchEmp360ProfileCreatorTypeName,
    fetchEmp360ProfileWatcherWorker,
  );
}

/**
 * Profile Worker
 */
export function* fetchEmp360ProfileWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360ProfileLoading(true));
    const fetchedProfile = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${action.payload}${API_PATH.employee360.profileDetails}`,
      {},
      {},
    );

    yield put(employee360StateActions.setEmp360Profile(fetchedProfile?.data));
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching employee profile data!',
    //   ToastType.ERROR,
    // );
    yield put(employee360StateActions.setEmp360ProfileError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ProfileError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360ProfileLoading(false));
  }
}

/**
 * Profile Personal Information Watcher
 */
export function* fetchEmp360ProfilePersonalInformationWatcher() {
  yield takeLatest(
    fetchEmp360ProfilePersonalInformationCreatorTypeName,
    fetchEmp360ProfilePersonalInformationWatcherWorker,
  );
}

/**
 * Profile Personal Information Worker
 */
export function* fetchEmp360ProfilePersonalInformationWatcherWorker(
  action: any,
): any {
  try {
    yield put(
      employee360StateActions.setEmp360ProfilePersonalInformationLoading(true),
    );
    const fetchedInfo = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${action.payload}${API_PATH.employee360.personalInformation}`,
      {},
      {},
    );

    yield put(
      employee360StateActions.setEmp360ProfilePersonalInformation(
        fetchedInfo?.data,
      ),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching employee personal information!',
      ToastType.ERROR,
    );
    yield put(
      employee360StateActions.setEmp360ProfilePersonalInformationError(error),
    );
    setTimeout(function* () {
      yield put(
        employee360StateActions.setEmp360ProfilePersonalInformationError(''),
      );
    }, 5000);
  } finally {
    yield put(
      employee360StateActions.setEmp360ProfilePersonalInformationLoading(false),
    );
  }
}

/**
 * Personal experience employees on hold Watcher
 */

export function* employeesOnHoldDetailsWatcher() {
  yield takeLatest(
    fetchEmployeesOnHoldDetailsTypeName,
    employeeOnHoldDetailsWorker,
  );
}

/**
 *  Default Gender and Religion watcher worker
 */

export function* DefaultGenderAndReligionWatcher() {
  yield takeLatest(
    fetchEmp360DefaultGenderAndReligionTypeName,
    DefaultGenderAndReligionWatcherWorker,
  );
}

export function* DefaultGenderAndReligionWatcherWorker(): any {
  const urlGender = `${API_PATH.employee360.gender}`;
  const urlReligion = `${API_PATH.employee360.religion}`;

  try {
    const [responseGender, responseReligion] = yield all([
      call(NetworkService.get, urlGender, {}, {}),
      call(NetworkService.get, urlReligion, {}, {}),
    ]);

    console.log('responseGender', responseGender);
    console.log('responseReligion', responseReligion);
    if (
      responseGender.status === HTTP_OK ||
      responseReligion.status === HTTP_OK
    ) {
      yield put(
        employee360StateActions.setEmp360GenderAndReligion({
          Gender: responseGender.data,
          Religion: responseReligion.data,
          genderError: false,
          religionError: false,
        }),
      );
    } else {
      yield put(
        employee360StateActions.setEmp360GenderAndReligion({
          Gender: [],
          Religion: [],
          genderError: true,
          religionError: true,
        }),
      );
      throw Error;
    }
    const genderAndReligionData = yield select(
      employee360StateSelector.getEmployee360DefaultGenderandReligion(),
    );
    console.log('genderAndReligionData', genderAndReligionData);
  } catch (error) {
    yield put(
      employee360StateActions.setemp360GenderAndReligionLoading({
        genderLoading: false,
        religionLoading: false,
      }),
    );
    ToasterService.showToaster(
      'Something went wrong while fetching Defult Gender and Religions options!',
      ToastType.ERROR,
    );
  }
}

/**
 * Personal experience employees on hold Worker
 */

export function* employeeOnHoldDetailsWorker(action: any): any {
  const userId = action.payload;
  console.log('inside saga', userId);
  const url = `${API_PATH.employee360.employeesOnHold}${userId}`;
  console.log('url', url);
  try {
    yield put(employee360StateActions.setEmployeesOnHoldLoading(true));
    const response = yield call(NetworkService.get, url, {}, {});
    console.log('empOnHoldres', response);
    if (response.status !== HTTP_OK) {
      throw Error;
    }
    yield put(
      employee360StateActions.setEmployeesOnHoldDetails(response?.data),
    );
  } catch (e) {
    ToasterService.showToaster(
      'Something went wrong while fetching employees on hold information!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmployeesOnHoldErrors(true));
  } finally {
    yield put(employee360StateActions.setEmployeesOnHoldLoading(false));
  }
}

/**
 * Personal experience employees on hold Watcher
 */

export function* fetchEmployee360WorkExperienceWatcher() {
  yield takeLatest(
    fetchEmployeesWorkExperienceDetailsTypeName,
    fetchEmployee360WorkExperienceWorker,
  );
}

export function* fetchEmployee360WorkExperienceWorker(): any {
  const url = `${API_PATH.employee360.workExperience}`;
  try {
    yield put(employee360StateActions.setEmployeesWorkExperienceLoading(true));
    const workExperienceResponse = yield call(NetworkService.get, url, {}, {});

    yield put(
      employee360StateActions.setEmployeesWorkExperience(
        workExperienceResponse.data,
      ),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching employees work experience information!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmployeesWorkExperienceError(error));
  } finally {
    yield put(employee360StateActions.setEmployeesWorkExperienceLoading(false));
  }
}

/**
 * Profile Education Watcher
 */
export function* fetchEmp360ProfileEducationWatcher() {
  yield takeLatest(
    fetchEmp360ProfileEducationCreatorTypeName,
    fetchEmp360ProfileEducationWatcherWorker,
  );
}

/**
 * Profile Education Worker
 */
export function* fetchEmp360ProfileEducationWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360ProfileEducationLoading(true));
    const fetchedInfo = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${action.payload}${API_PATH.employee360.education}`,
      {},
      {},
    );
    if (fetchedInfo.status === HTTP_OK) {
      yield put(
        employee360StateActions.setEmp360ProfileEducation(fetchedInfo?.data),
      );
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching employee education data!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360ProfileEducationError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ProfileEducationError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360ProfileEducationLoading(false));
  }
}

/**
 * Course Details Watcher
 */

export function* fetchEmp360CourseDetailsWatcher() {
  yield takeLatest(
    fetchEmp360ProfileCourseDetailsCreatorTypeName,
    fetchEmp360CourseDetailsWorker,
  );
}

export function* fetchEmp360CourseDetailsWorker(action: any): any {
  const id = action.payload;
  try {
    yield put(employee360StateActions.setEmp360ProfileCourseLoading(true));
    const fetchedCourses = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${id}${API_PATH.employee360.courses}`,
      {},
      {},
    );
    if (fetchedCourses.status === HTTP_OK) {
      yield put(
        employee360StateActions.setEmp360ProfileCourseDetails(
          fetchedCourses?.data,
        ),
      );
    }
  } catch (error) {
    yield put(employee360StateActions.setEmp360ProfileCourseDetails([]));
    // ToasterService.showToaster(
    //   'Something went wrong while fetching course details!',
    //   ToastType.ERROR,
    // );
    yield put(employee360StateActions.setEmp360ProfileCourseError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ProfileCourseError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360ProfileCourseLoading(false));
  }
}

/**
 * Award Details Watcher
 */

export function* fetchEmp360AwardDetailsWatcher() {
  yield takeLatest(
    fetchEmp360ProfileAwardDetailsCreatorTypeName,
    fetchEmp360AwardDetailsWorker,
  );
}

/**
 * Award Details Worker
 */

export function* fetchEmp360AwardDetailsWorker(action: any): any {
  const id = action.payload;
  try {
    yield put(employee360StateActions.setEmp360ProfileAwardLoading(true));
    const fetchedAwards = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${id}${API_PATH.employee360.awards}`,
      {},
      {},
    );
    if (fetchedAwards.status === HTTP_OK) {
      yield put(
        employee360StateActions.setEmp360ProfileAwardDetails(
          fetchedAwards?.data,
        ),
      );
    }
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching Award details!',
    //   ToastType.ERROR,
    // );
    yield put(employee360StateActions.setEmp360ProfileAwardDetails([]));
    yield put(employee360StateActions.setEmp360ProfileAwardError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ProfileAwardError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360ProfileAwardLoading(false));
  }
}

/**
 * Foreign Trips Watcher
 */

export function* fetchEmp360ForeignTripsWatcher() {
  yield takeLatest(
    fetchEmp360ProfileForeignTripsCreatorTypeName,
    fetchEmp360ForeignTripsWorker,
  );
}

/**
 * Foreign Trips Worker
 */

export function* fetchEmp360ForeignTripsWorker(action: any): any {
  const id = action.payload;
  try {
    yield put(
      employee360StateActions.setEmp360ProfileForeignTripsLoading(true),
    );
    const fetchedForeignTrips = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${id}${API_PATH.employee360.foreignTrips}`,
      {},
      {},
    );
    yield put(
      employee360StateActions.setEmp360ProfileForeignTrips(
        fetchedForeignTrips?.data,
      ),
    );
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching Foreign Trips details!',
    //   ToastType.ERROR,
    // );
    yield put(employee360StateActions.setEmp360ProfileForeignTrips([]));
    yield put(employee360StateActions.setEmp360ProfileForeignTripsError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ProfileForeignTripsError(''));
    }, 5000);
  } finally {
    yield put(
      employee360StateActions.setEmp360ProfileForeignTripsLoading(false),
    );
  }
}

/**
 * Profile Contact Details Watcher
 */
export function* fetchEmp360ContactDetailsWatcher() {
  yield takeLatest(
    fetchEmp360ProfileContactDetailsTypeName,
    fetchEmp360ContactDetailsWatcherWorker,
  );
}

/**
 * Profile Contact Details Worker
 */
export function* fetchEmp360ContactDetailsWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360ContactDetailsLoading(true));
    const fetchedInfo = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${action.payload}${API_PATH.employee360.contactDetails}`,
      {},
      {},
    );

    yield put(
      employee360StateActions.setEmp360ContactDetails(fetchedInfo?.data),
    );
  } catch (error) {
    // ToasterService.showToaster(
    //   'Something went wrong while fetching employee contact details!',
    //   ToastType.ERROR,
    // );
    yield put(employee360StateActions.setEmp360ContactDetails([]));
    yield put(employee360StateActions.setEmp360ContactDetailsError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ContactDetailsError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360ContactDetailsLoading(false));
  }
}

/**
 * Profile Family Details Watcher
 */
export function* fetchEmp360FamilyDetailsWatcher() {
  yield takeLatest(
    fetchEmp360ProfileFamilyDetailsTypeName,
    fetchEmp360FamilyDetailsWatcherWorker,
  );
}

/**
 * Profile Family Details Worker
 */
export function* fetchEmp360FamilyDetailsWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360FamilyDetailsLoading(true));
    const fetchedInfo = yield call(
      NetworkService.get,
      `${API_PATH.employee360.empProfile}${API_PATH.employee360.directDependentDetails}${action.payload}`,
      {},
      {},
    );

    yield put(
      employee360StateActions.setEmp360FamilyDetails(fetchedInfo?.data),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching employee Family details!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360FamilyDetailsError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360FamilyDetailsError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360FamilyDetailsLoading(false));
  }
}

/**
 * Profile Company Assests Watcher
 */
export function* fetchEmp360CompanyAssetsWatcher() {
  yield takeLatest(
    fetchEmp360ProfileCompanyAssetsTypeName,
    fetchEmp360CompanyAssetsWatcherWorker,
  );
}

/**
 * Profile Company Assests Worker
 */
export function* fetchEmp360CompanyAssetsWatcherWorker(action: any): any {
  try {
    yield put(employee360StateActions.setEmp360CompanyAssetsLoading(true));
    const fetchedInfo = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${action.payload}${API_PATH.employee360.companyAssets}`,
      {},
      {},
    );
    if (fetchedInfo.status === HTTP_OK) {
      const data = fetchedInfo?.data?.map((val: any, index: any) => {
        return {val, ...{id: index}};
      });
      yield put(employee360StateActions.setEmp360CompanyAssets(data));
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching employee company assets!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360CompanyAssetsError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360CompanyAssetsError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360CompanyAssetsLoading(false));
  }
}

/**
 * Feedbacks Watcher
 */
export function* fetchEmp360FeedbacksWatcher() {
  yield takeLatest(
    fetchEmp360ProfileFeedbacksCreatorTypeName,
    fetchEmp360FeedbacksWorker,
  );
}

/**
 * Feedbacks Worker
 */
export function* fetchEmp360FeedbacksWorker(action: any): any {
  const id = action.payload;
  try {
    yield put(employee360StateActions.setEmp360ProfileFeedbacksLoading(true));
    const fetchedFeedbacks = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${id}${API_PATH.employee360.feedbacks}`,
      {},
      {},
    );

    yield put(
      employee360StateActions.setEmp360ProfileFeedbacks(fetchedFeedbacks?.data),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching Feedbacks!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360ProfileFeedbacksError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ProfileFeedbacksError(''));
    }, 5000);
  } finally {
    yield put(employee360StateActions.setEmp360ProfileFeedbacksLoading(false));
  }
}

/**
 * Assessment Watcher
 */

export function* fetchEmp360AssessmentsWatcher() {
  yield takeLatest(
    fetchEmp360ProfileAssessmentsCreatorTypeName,
    fetchEmp360AssessmentsWorker,
  );
}

/*
 * Assessment Worker
 */

export function* fetchEmp360AssessmentsWorker(action: any): any {
  const id = action.payload;
  try {
    yield put(employee360StateActions.setEmp360ProfileAssessmentsLoading(true));
    const fetchedAssessments = yield call(
      NetworkService.get,
      `${API_PATH.employee360.profile}${id}${API_PATH.employee360.assessments}`,
      {},
      {},
    );

    yield put(
      employee360StateActions.setEmp360ProfileAssessments(
        fetchedAssessments?.data,
      ),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching Assessment details!',
      ToastType.ERROR,
    );
    yield put(employee360StateActions.setEmp360ProfileAssessmentsError(error));
    setTimeout(function* () {
      yield put(employee360StateActions.setEmp360ProfileAssessmentsError(''));
    }, 5000);
  } finally {
    yield put(
      employee360StateActions.setEmp360ProfileAssessmentsLoading(false),
    );
  }
}
