import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {LeftSideBar} from '../left-sidebar';
import {RightSidebar} from '../right-sidebar';
import {NavBar} from '../navbar';
import {useSelector, shallowEqual,RootStateOrAny} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {authSelector} from '@app/screens/generic/Login/redux';
import { approveExpenseStateSelector } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import { ReactElement } from 'react';

const drawerWidth = 240;

const Main: any = styled('main', {
  shouldForwardProp: prop => prop !== 'open',
})((props: any) => ({
  flexGrow: 1,
  padding: '5px 0px 0px 0px',
  width: 'calc(100% - 525px)',
  borderTopRightRadius: 25,
  backgroundColor: '#fff',
  borderTopLeftRadius: 25,
  transition: props.theme.transitions.create('margin', {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(props.open && {
    transition: props.theme.transitions.create('margin', {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')<{navbarShow: boolean}>(
  ({theme, navbarShow}) => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: navbarShow ? 150 : 0,
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }),
);

export const Layout = ({children}: {children: ReactElement}) => {
  const sideBarOpen = useSelector(routeSelector.getSideBarOpen(), shallowEqual);
  const isAuthorized = useSelector(
    authSelector.getIsAuthorized(),
    shallowEqual,
  );
  const screenDetails = useSelector(
    approveExpenseStateSelector.getApproveExpenseScreen()
  );

  console.log('screenDetails',screenDetails)
  const screenNumber = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.screenNo,
    shallowEqual,
  );
  const navbarShow = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.navbarShow,
  );

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      {navbarShow && <NavBar authorized={isAuthorized} />}
      <LeftSideBar authorized={isAuthorized} />
      <Main open={sideBarOpen}>
        <DrawerHeader navbarShow={navbarShow} />
        <ToastContainer />
        <Box>{children}</Box>
      </Main>
      {isAuthorized && screenDetails?.screen !== 2 && screenNumber === 0 && (
        <RightSidebar />
      )}
    </Box>
  );
};
